input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

a {
  text-decoration: none;
}

main > div:first-of-type {
  min-width: 100% !important;
  /* min-height: 100vh !important; */
}

body{
  font-family: Open Sans !important;
  background-color: #ffffff !important;
  /* width: 1280px !important; */
  margin: auto !important;

}
.Recruiter_create-account, .dashboard-container{
  width: 1280px !important;
  margin: auto;
}
.top-header{
  padding: 15px 0 !important;
}
.recruiterDashboard .MuiGrid-container{
  margin: auto;
  width: 98%;
}
/* 
table tr th:nth-child(5) {
  position: sticky;
  z-index: 1070;
}
table tr th:nth-child(6) {
  position: sticky;
  left: 75px;
  z-index: 1070;
}

table tr td:nth-child(5) {
  position: sticky;
  left: 0px;
  z-index: 1070;
  background-color: inherit;
}
table tr td:nth-child(6) {
  position: sticky;
  left: 75px;
  z-index: 1070;
  background-color: inherit;
} */
.MuiAppBar-colorPrimary {
   
  height: 80px;
  margin: 0 0 42px;
  padding: 15px 35px;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07);
  background-color: #ffffff;


}

.makeStyles-logo-2 {

  width: 86.2px !important;

  height: 53px;

  margin: 0 844.8px 0 0;

  object-fit: contain;

}

.PrivateTabIndicator-colorSecondary-5 {
  background-color: #ffffff !important;
}

.main-content{
  width: 1280px !important; 
 /* 1280pxrespons */
  top: 4%;
  margin: 42px auto;
  /* padding: 55px 40px;  // 1280pxrespons */
  padding: 55px 0px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
  background-color: #ffffff;
}
.login-register.main-content{
  box-shadow: none;

}
.main-content.profile-accordian{
  box-shadow: none;
  padding: 0;
}
.header-image{
  margin: 0 auto;
  float: right;
}
.header-image img{
  border-radius: 68px;
 
}
.MuiAccordionSummary-content.Mui-expanded {
  padding-bottom: 20px;
  border-bottom: 1px #cccccc solid;
}

.mini-Profile{
  text-align: right;
}
.login-link{
  width: 100%;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.95;
    letter-spacing: normal;
    text-align: left;
    color: #1c75bc;
}

.action-button .save-button{
  padding: 10px 40px;
  border-radius: 9px;
  background-color: #1c75bc;
  text-transform: capitalize;
    font-size: 17px;
    font-family: Open Sans !important
    ;
    font-weight: bold;
}
.action-button .cancel-button{
  padding: 10px 40px;
  border-radius: 9px;
  border: solid 1px #1c75bc;
  background-color: #ffffff;
  text-transform: capitalize;
  font-size: 17px;
  font-family: Open Sans !important;
  font-weight: bold;
}

.action-button{
  direction: rtl;
  font-family: Open Sans !important;
}

.MuiInputBase-input {
  padding: 14px 35px !important;
  border-radius: 9px !important;
}

.link-white{
  color: #ffffff;
  text-decoration: none;
}

.body3.icons{
position: absolute;
}
.social-icon {
  position: absolute;
  right: 0px;
}
.social-icon img.Page-1 {
  padding: 0 5px;
}
.footer-devider{
  border-bottom: 1px solid #ffffff;
}
footer .MuiContainer-root.MuiContainer-maxWidthLg{
padding-left: 0;
padding-right: 0px;
}
.faq{
  font-weight: bold;
}

.success-page{
  margin: 0 auto;
  width: 100%;
}

.success-h4{
  font-family: Open sans;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.14;
  letter-spacing: normal;
  text-align: center;
  color: #455a64;
}

.success-p{
  font-family: Open sans;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.75;
    letter-spacing: normal;
    text-align: center;
    color: #333333;
}

#root .MuiTabs-indicator{
  background-color: #ffffff !important;
}


.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
  clear: both;
  display: unset;
}
  .makeStyles-logo-2 {
    float:left;
    margin:0;
  }
  
  .MuiTabs-root.header-right {
    float: right;
    padding-top: 14px;
  }
    
  .MuiTab-wrapper {
      font-family: Open sans;
      font-size: 17px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.06;
  letter-spacing: normal;
  text-align: left;
  color: #333333 !important;
  text-transform: initial;
  }

.dashboard-container {
    width: 100%;
    margin: 0 auto;
    padding: 15px 35px;
}


.page-heading{
  font-family: Open sans;
  font-size: 25px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16;
  letter-spacing: normal;
  text-align: left;
  color: #455a64;
}

.customer-support{
  margin: 0 0 21px 2px;
  padding: 22px 13px 21.2px 6px;
  background-color: #e8e8e8;
}

.support-head{
  width: 100%;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #1c75bc;
  display: grid;
}
.support-head span{
  font-size: 14px;
  color: #5cb85c;
}
.customer-support .devider{
  margin: 15px 0;
}

.Data-assigned {
  margin: 5px 14px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #000000;
  display: inline-block;
}
.Data-assigned .text-style {
  font-weight: normal;
}

.Data-assigned .text-style ul{
  padding: 0;
}

.Data-assigned .text-style ul li {
  display: block;
}
.support-content .MuiGrid-item{
  display: inline-block;
  vertical-align: top;
}
.payout-button{
  width: 100%;
  float: right;
}

.payout-button .Data-assigned{
  padding: 6px 15px;
  border-radius: 2px;
  border: solid 1px #1c75bc;
  text-align: center;
  width: 70%;
  float: right;
  color: #1c75bc;
}
.Data-assigned.candidate-info {
  padding: 6px 10px;
  background-color: #fff;
  width: 140px;
  margin: 10px 6px;
  text-align: center;
  display: inline-flex;
  font-size: 25px;
  font-weight: bold;
  line-height: 0.84;
  letter-spacing: normal;
  text-align: left;
  color: #333333;
}
.Data-assigned.candidate-info:last-child{
  color: #5cb85c;
}

.Data-assigned.candidate-info span {
  font-size: 13px;
  line-height: 1.15;
  font-weight: bold;
  padding-left: 10px;
}

.font-red .Data-assigned.candidate-info{
  color: #d9534f;
  font-size: 14px;
  font-weight: 600;
}


.page-filter select{
  padding: 7px 12.7px 10px 20px;
    border-radius: 4px;
    border: solid 1px #cccccc;
    background-color: #ffffff;
    color: #333333;
    font-size: 14px;
    font-family: 'Open Sans';
}

.text-center{
  text-align: center;
}

.text-right{
  text-align: right;
}

.text-left{
  text-align: left;
}


.ABS{
  position: initial !important;
  transform: none !important;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  width: 200px;
  
}
.ABSD{
  margin: 10px 1px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.13);
  background-color: #ffffff;
  width: 99%;
}
.ABSD li{
  margin: 5px;
  border-bottom: solid 1px #e8e8e8;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  text-align: left;
  color: #455a64;
}
.ABSD li a{
  color: #455a64;

}
.ABSD li.profile-active{
  font-weight: bold;
  color: #333333;
}
.ABSD li:last-child {
  border-bottom: none;
}
.MuiAlert-filledError{
  color: #D63838 !important;
  background-color: #FBD3D3 !important;
}
.makeStyles-paper-10{
  width: 768px !important;
}

.MuiTab-textColorInherit.Mui-selected, .MuiTab-textColorInherit {
  opacity: unset !important;
}

a.menuBar {
    height: 23px;
    /* margin: 18px 74px 10.1px 110.8px; */
    font-family: Open Sans;
    font-size: 17px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.06;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
}
.logotop span.MuiTab-wrapper {
  align-items: flex-start;
}
.activeLink span.MuiTouchRipple-root {
  border-bottom: 6px solid #1c75bc;
}
.activeLink .menuBar{
  color: #1c75bc;
}

.vertical-align{
  vertical-align: middle;
}
.myProfile-container{
  display: inline-block;
  vertical-align: top;
}
.MuiGrid-root.myProfile-container.MuiGrid-item.MuiGrid-grid-xs-4, .MuiGrid-root.myProfile-container.MuiGrid-item.MuiGrid-grid-xs-5 {
  padding-right: 10%;
}

.terms-popup:focus {
    outline: none;
}

/* .terms-popup{
  height: calc(100% - 50px);
overflow-y: auto;
scrollbar-width: thin;
} */
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #333333; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b30000; 
}

.selectedFile{
  color: green;
}

.forgot-label-text{
  width: 286px;
  margin: 0 auto 29px !important
}

.forgot-label-text .form-label{
  line-height: 1.56;
}

button.MuiButtonBase-root.MuiIconButton-root.closeButtonIcon {
  top: 8px;
  color: #9e9e9e;
  right: 8px;
  position: absolute;
}

.faq_container{
  width: 1024px;
  margin: auto;
  padding: 55px 43px 50px 44px;
  border-radius: 10px;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 18%);
  background-color: #ffffff;
  margin-bottom: 100px;
}

.accordian_container{
  padding: 15px 0;
  border-radius: 15px;
 background-color: #ffffff;
 border-bottom: solid 1px #e9e9e9;
 box-shadow: none !important;
}

.MuiAccordionSummary-content p{
  font-family: Open Sans !important;
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  text-align: left;
  color: #1c75bc;
}
.accordian_container .MuiAccordionSummary-content.Mui-expanded{
  border: none;
  padding: 0px;
}

.faq_container .MuiAccordion-root.Mui-expanded{
  padding: 0;
}
.faq_top.bgcolorf6f6ff{
  position: relative;
  min-height: 340px;
  top: -40px;
}
.faq_body{
    position: absolute;
    top: 440px;
    width: 100%;
}

.faq_head {
  /* margin-top: 56px; */
  font-family: Open Sans;
  font-size: 42px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #1c75bc;
}
.faq_subtitle{
  height: 34px;
  margin: 17px 0 23px 0;
  font-family: Open Sans;
  font-size: 25px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.36;
  letter-spacing: normal;
  text-align: center;
  color: #1c75bc;

}
.faq_title_desc{
  height: 24px;
  margin: 23px 0 151px 0;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}

.interviewDashboardTabs{
  padding: 0;
  margin-bottom: 0;
}
.interviewDashboardTabs .react-tabs__tab{
  border-color: transparent !important ;
  border-top-left-radius:4px;
  background: white !important;
  font-weight: bold;
  border-top-right-radius:4px;
  box-shadow: 0px -6px 10px -4px rgb(0 0 0 / 16%);
  color: #707070 !important;
  margin-right: 8px;
}
.interviewDashboardTabs .react-tabs__tab--selected{
  color: #1c75bc !important;
  background: white !important;
  /* border-color: transparent !important ; */
  box-shadow: 0px -6px 10px -4px rgb(0 0 0 / 16%);
}
.interviewDashboardTabs .react-tabs__tab-panel--selected{
  /* border: 1px solid #cfcfcf; */
  border-radius: 8px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  border-top-left-radius: unset;
  padding: 15px;
  background-color: white;
}
.interviewDashboardTabContainer .react-tabs__tab-panel--selected{
  /* border: 1px solid #cfcfcf; */
  border-radius: 8px;
  border-top-left-radius: unset;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.16);
  padding: 15px;
  background-color: white;
}