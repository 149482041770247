.videoContainer{
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-bottom: solid 0.5px #c1c1c1;
    &:hover{
        cursor: pointer;
    }
}
.videoThumbnail{
    height: 110px;
}
.videoTitle{
    font-size: 18px;
    font-weight: 600;
    color: #333333;
}
.tabHeading{
    display: flex;
    font-size: 20px;
    img{
        margin-right: 8px;
    }
}
.tabsContainer{
    position: relative;
    top: -210px;
    // background-color: white;
}