.opensansFont{
    font-family: Open Sans !important;
}
.blueBackground{
    background-color: #1c75bc;
}
.displayFlex{
    display: flex;
}
.whiteColor{
    color: #ffffff;
}
.widthFitContent{
    width: fit-content;
}
.alignItemsCenter{
    align-items: center;
    
}
.ourClients{
margin: 60px auto;
}
.alignSelfFlexStart{
    align-self: flex-start;
}
.fontSize14{
    font-size: 14px;
}
.fontSize16{
    font-size: 16px;
}
.fontSize18{
    font-size: 18px;
}
.fontSize20{
    font-size: 20px;
}
.fontSize24{
    font-size: 24px;
}
.fontSize28{
    font-size: 28px;
}
.fontSize32{
    font-size: 32px;
}
.fontWeightBold{
    font-weight: bold;
}
.width55percent{
    width: 55%;
}
.textAlignCenter{
    text-align: center;
}
.textAlignEnd{
    text-align: end;
}
.blueColor{
    color: #1c75bc;
}
.paddingHorizontal{
    padding: 0 100px;
}
.paddingHorizontal5percent{
    padding: 0 5% !important;
}
.paddingTop50{
    padding-top: 50px;
}
.paddingTop150{
    padding-top: 150px;
}
.paddingTop20{
    padding-top: 20px;
}
.paddingTop100{
    padding-top: 100px;
}
.margin0{
    margin: 0 !important;
}
.yellowRectangle{
    background-color: #ffde17;
    height: 7px;
    width: 68px;
}
.absolutePosition{
    position: absolute;
}
.flexDirectionColumn{
    flex-direction: column;
}
.cardStyles{
    width: 286px;
    height: 210px;
    background-color: #eaf1f8;
    border-radius: 21px;
    /* padding: 30px; */
    margin: 20px 20px 20px 0;
}
.zIndex-1{
    z-index: -1;
}
.padding100{
    padding: 100px;
}
.padding20-30{
    padding: 20px 30px;
}
.overflowHidden{
    overflow: hidden;
}
.positionRelative{
    position: relative;
}
.imageRight-100{
    right: -100px;
}
.imageLeft-100{
    left: -100px;
}
.margin20{
    margin:20px;
}

.margin5-14{
    margin-top: 5px;
    margin-bottom: 14px;
}
.marginLeft-170px{
    margin-left: -170px;
}
.marginTop8{
    margin-top: 8px !important;
}
.marginTop50{
    margin-top: 50px !important;
}
.marginTop35{
    margin-top: 35px !important;
}
.marginTop100{
    margin-top: 100px !important;
}
.marginTop20{
    margin-top: 20px !important;
}
.marginTop25{
    margin-top: 25px !important;
}
.marginTop10{
    margin-top: 10px !important;
}
.marginBottom35{
    margin-bottom: 35px ;
}
/* .marginTopRightBottom20 {
    margin: 20px 19px 20px 0 !important;
} */
.paddingLeft20only {
    padding: 0 0 0 20px!important;
}
.laptopImage{
    margin-top: 50px;
    width: auto;
    max-height: 400px;
}
.paddingLeft5Percent{
    padding-left: 5%;
}
.paddingTopBottom35{
    padding-top: 35px !important;
    padding-bottom: 35px !important;
}
.paddingLeft82px{
    padding-left: 82px;
}
.paddingBottom50{
    padding-bottom: 50px;
}
.yellowLinesPosition{
    position: absolute;
    /* margin-top: -550px; */
    margin-left: 0px;
}
.yellowLinesPositionRight{
    position: absolute;
    right: -45px;
    bottom: 0px;
}
.FAQButton{
    border: 1px solid #1c75bc !important;
    border-radius: 9px !important;
    font-size: 24px !important;
    font-weight: bold !important;
    color: #1c75bc !important;
    margin-top: 35px !important;
    width: 250px !important;
    text-transform: unset !important;
    font-family: Open Sans !important;
}
.FAQButton a{
    color: #1c75bc !important;
}
.recruiterImage{
    position: relative;
    top: -33px;
    padding: 0 6%;
    width: 93%;
}
.recruiterImageDiv{
    margin-bottom: -15px !important;
    
}
.RegisterNowButton{
    /* border: 1px solid #1c75bc !important; */
    background-color: #ffffff !important;
    border-radius: 9px !important;
    font-size: 24px !important;
    font-weight: bold !important;
    color: #1c75bc !important;
    margin-top: 35px !important;
    width: 250px !important;
    text-transform: unset !important;
    font-family: Open Sans !important;
    padding: 6px 8px;
    text-align: center;
}
.loginFormContainer {
    border-radius: 29px;
    box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
    padding: 30px 45px;
    /* margin-left: 7%; */
}
.forgotPassword{
    text-decoration: underline !important;
    font-size: 18px !important;
    color: #1c75bc !important;
    text-transform: unset !important;
    padding: 0 !important;
}
.forgotPassword:hover{
    background-color: unset !important;
}
.LoginButton{
    border-radius: 9px !important;
    background-color: #1c75bc !important;
    font-size: 20px !important;
    color: #ffffff !important;
    text-transform: unset !important;
    width: 65% ;
}
.createAccount, .resendLink{
    text-decoration: underline !important;
    font-size: 20px !important;
    color: #1c75bc !important;
    text-transform: unset !important;
    padding: 6px 8px;
    font-weight: bold;
    line-height: 1.75;
    border-radius: 4px;
    letter-spacing: 0.02857em;
}
.resendLink{
    font-size: 14px !important;
  
}
.createAccount:hover{
    background-color: unset !important;
}
.error{
    border-color: red;
}
.errorFont{
    color: red;
}


.footer-container{
        flex-grow: 0;
        max-width: 100%;
        flex-basis: 100%;
        margin: 0px 0 0;
        object-fit: contain;
        background-color: #292929;
        /* padding: 0 100px; */
        color: #ffffff;
      }

      .footer-container .MuiContainer-maxWidthSm{
        max-width: 100%;
        padding: 20px 100px;
    border-bottom: solid 1px #ffffff;
      }

      .footer-container .MuiContainer-maxWidthSm p {
        width: 354px;
        font-family: Roboto;
        font-size: 14px;
        text-align: left;
        color: #ffffff;
    }

    .layer{
        width: 52.5px;
        object-fit: contain;
        padding: 20px 0;
    }
    .body3 {
        width: 30%;
        display: inline-flex;
    }

    .icon, .text {
  vertical-align: middle;
  display: inline-block;
}
.svg-disabled {
    color: #525252;
}

span.icon {
    position: absolute;
   
}
span.text {
    position: relative;
    list-style: none;
    padding-left: 38px;
    margin-bottom: 10px;
}

.common_tab{
    width: 260px;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.35;
  letter-spacing: normal;
  text-align: center;
  color: #333333;
}
.image-container {
    position: relative;
    text-align: center;
    margin: 0 auto;
  }
  
  /* Bottom left text */
  .bottom-left {
    position: absolute;
    bottom: 8px;
    left: 16px;
  }
  
  /* Top left text */
  .top-left {
    position: absolute;
    top: 48%;
    left: 16px;
  }
  
  /* Top right text */
  .top-right {
    position: absolute;
    top: 8px;
    right: 16px;
    width: 30%;
  }
  
  /* Bottom right text */
  .bottom-right {
    position: absolute;
    bottom: 0;
    right: 16px;
    width: 35%;
  }
  
  /* Centered text */
  .centered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .MuiFormControl-root.MuiTextField-root{
      width: 100%;
  }

  .loginFormContainer .MuiOutlinedInput-input {
    padding: 14px 26px !important;
}
.loginFormContainer .displayFlex{
    padding-bottom: 0 !important;
}

.loginFormContainer .paddingTop20{
    padding-top: 20px !important;
}
.login-register .displayFlex{
    position: unset;
}

.email-verification .success-page {
    padding-top: 6%;
}
.email-verification .success-p {
    font-size: 18px;
}

.recruitmentPlatform{
    background-image: url("../assets/Group 2973.png");
    background-repeat: no-repeat;
}
.industryTogethrt{
    display: flex;
}