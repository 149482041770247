.buttonList{
    display: flex;
    justify-content: space-between;
}

.dataButton{
    border-radius: 5px !important;
    background-color: #1c75bc !important;
    color: white !important;
    margin-right: 20px !important;
    height: fit-content !important;
}
.dataButton:disabled{
    background-color:rgba(0, 0, 0, 0.12) !important;
    background-color:rgba(0, 0, 0, 0.26) !important;
}
.uploadButton{
    border: 1px solid #1c75bc !important;
    color: #1c75bc !important;
}
.flexRowReverse{
    display: flex;
    flex-direction: row-reverse;
}
.tableRow:hover{
    background-color:#cccccc
}
.editButtonClass{
    margin-top: -2px;
    margin-left: 0;
    padding-left: 0;
}
.fontBold600{
    font-weight: 600;
}

.flexDisplay{
    display: flex;
}
.fitContentHeight{
    height: fit-content;
}
.FilterHeading{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    padding-left: 20px;
}
.centerFlex{
    display: flex;
    align-items: center;
}
.color1c75bc {
    color: #1c75bc !important;
}
.padding20{
    padding: 20px;
}
.paddingTop20Other0{
    padding: 20px 0 0 0 !important;
}
.marginLight20{
    margin-left: 20px !important;
}
.marginLeft-20{
    margin-left: -20px !important;
}
.marginRight20{
    margin-right: 20px !important;
}
.padding10{
    margin: 10px !important;
}
.marginVertical20{
    margin: 20px 0 !important;
}
.filterCLoseButton{
    height: 16px;
    width: 16px;
    margin-top: 26px !important;
    left: -66px;
}
.marginVertical40{
    margin: 40px 0 !important;
}
.paddingVertical40{
    padding: 40px 0 !important;
}
.paddingVertical20{
    padding: 20px 0 !important;
}
.paddingVertical12{
    padding: 12px 0 !important;
}
.marginBottom36{
    margin-bottom: 36px !important;
}
.marginBottom12{
    margin-bottom: 12px !important;
}
.marginBottom20{
    margin-bottom: 20px !important;
}
.marginBottom20Only{
    margin:0 0  20px 0 !important;
}
.underLineText{
    text-decoration: underline;
}
.paddingLeft36{
    padding-left: 36px;
}
.paddingLeft25{
    padding-left: 25px !important;
}
.recSortOrderButton{
    border-bottom-left-radius: 0px !important;
    border-top-left-radius: 0px !important;
    margin-top: 20px !important;
    margin-left: -20px !important;
    height: 35px !important;
    width: 35px;
}

.blueColor{

    color: #1c75bc !important;
}
.displayNone{
    display: none !important;
}
.margin0{
    margin: 0 !important;
}
.margin16px0{
    margin: 16px 0 !important;
}
.flexheight100{
    display: flex;
    height: 100%;
}
.marginNum{
    margin: 15px 10px 0 15px !important;
}
.borderLightGrey{
    border: 1px solid lightgray !important;
    border-radius: 5px !important;
}
.marginHorizontal20{
    margin: 20px 0;
}
.greenColorBorder{
    color: #5cb85c !important;
    border: 1px solid #5cb85c !important;
}
.paddingHorizontal{
    padding-left: 8px !important;
    padding-right: 8px !important;

}
.background_color_eeeeee{
    background-color: #eeeeee;
}
.background_color_e8e8e8{
    background-color: #e8e8e8;
}
.background_color_ffffff{
    background-color: #ffffff;
}
.blueLink{
    color: #1c75bc;
}
.greenFlag{
    color: #388e3c;
}
.textCapitalize{
    text-transform: capitalize !important;
}
.fontSize16{
    font-size: 16px !important;
}
.fontSize14{
    font-size: 14px !important;
}
.fontSize13{
    font-size: 13px !important;
}
.fontSize18{
    font-size: 18px !important;
}
.fontSize24{
    font-size: 24px !important;
}
.fontSize32{
    font-size: 32px !important;
}
.fontWeightBold{
    font-weight: bold;
}
.fontWeightBolder{
    font-weight: bolder;
}
.textUnderline{
    text-decoration: underline;
}
.textAlignLeft{
    text-align: left;
}
    
.marginTopLeft0{
    margin-left: 0 !important;
    margin-top: 0 !important;
}
.marginLeft0{
    margin-left: 0 !important;
}

.margin20{
    margin: 20px !important;
}
.flexWrap{
    flex-wrap: wrap;
}

.filterButton{
    border-radius: 5px !important;
    border: 1px solid #cccccc !important;
    color: #4f4f4f !important;
    height: fit-content !important;
    padding: 5px !important;
    text-transform: none !important;
    width: auto;
}
.borderBottom{
    border-bottom: 1px solid #00000033;
}
.paddingTop{
    padding-top: 20px;
}
.blueOutlineButton{
    width: 190px;
    border-radius: 2px !important;
    border: solid 1px #1c75bc !important;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    text-align: left;
    color: #1c75bc !important;
    margin-bottom: 12px;
    float: right;
}
.canStatus{
    width: 134px;
    height: auto;
    border-radius: 5px;
}
.width69{
    width: 69px;
}
.marginTop26{
    margin-top: 26px !important;
}
.markLeftButton{
    border: 1px solid #d9534f !important;
    color: #d9534f !important;
    width: 100% !important;
    height: 35px !important;
}
.redButton{
    height: 26px;
    font-family: OpenSans;
    text-transform: capitalize !important;
    font-size: 14px !important;
    width: 170px;
    padding: 0 !important;
    background-color: #ffffff !important;
}
.candidateStatusButton{
    background-color:#ffffff !important;
    text-transform: capitalize !important;
    max-width: 140px;
    margin-right: 10px !important;
}
.containerHistoryFirst {
    width: 1px;
    top:6px;
    height: 100%;
    background-color: #cccccc;
    position:relative;
}
.circleHistoryFirst {
    display:inline-block;
    width: 10px;
    height: 10px;
    position: absolute;
    left:-5px;
    right:0;
    margin:0 auto;
    border-radius: 100%;
    top: 0px;
}
.containerHistory {
    width: 1px;
    height: 100%;
    background-color: #cccccc;
    position:relative;
}
.circleHistory {
    display:inline-block;
    width: 10px;
    height: 10px;
    position: absolute;
    left:-5px;
    right:0;
    margin:0 auto;
    border-radius: 100%;
    top: 6px;
}
.greyColor{
    color:#cccccc;
}
.greyColorBackground{
    background-color:#cccccc;
}
.greenColorBackground{
    background-color:#5cb85c;
}
.redColorBackground{
    background-color:#d9534f;
}
.blueColorBackground{
    background-color:#1c75bc;
}