.blueContainedButton{
    border-radius: 4px;
    background-color: #1c75bc;
    color: #ffffff;
    width: 146px;
    height: 50px;
    font-size: 17px;
    border: unset;
    &:disabled{
        background-color: #cccccc;
    }
    &:hover{
        background-color: darken(#1c75bc, 4%);
    }
} 
.blueOutlinedButton{
    border-radius: 4px;
    border: solid 1px #1c75bc;
    background-color: #ffffff;
    color: #1c75bc;
    width: 146px;
    height: 50px;
    font-size: 17px;
}
.paper{
    width: 600px;
    border-radius: 0 !important;
    max-width: 800px !important;
}
.rejectedBy{
    padding: 10px;
    font-size: 16px;
}