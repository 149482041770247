
.rdt_Table{
    width: max-content;
}
.rdt_TableHeadRow{
    background-color: #1c75bc !important;
}
/* .rdt_TableCol{
    color:white !important;
} */

.rdt_TableCol_Sortable{
    color:white !important;
    min-width: 150px;
}
.rdt_TableCell{
    border: 1px solid #dddddd;
}
.tabContainer{
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    border-top-left-radius: unset;
    padding: 15px;
}
.ReactTable .rt-thead.-header{
    background-color: #1c75bc !important;
    color:white !important;
    font-weight: bold;
}
.ReactTable .rt-thead .rt-tr{
    text-align: left !important;
}

.ReactTable .rt-tbody .rt-tr-group{
    border-bottom: solid 1px #c3c3c3 !important;
}
.ReactTable .rt-tbody .rt-td{
    border-right: 1px solid #c3c3c3 !important;
}
.stickCheckbox{
    position: sticky;
    left: 0;
}
.stickWorkerId{
    position: sticky;
    left: 0;
    border-right: 1px solid #1c75bc;
}
.stickWorkerIdnewMatch{
    position: sticky;
    left: 72px;
    border-right: 1px solid #1c75bc;
}
.stickWorkerName{
    position: sticky;
    left: 123px;
}
.stickWorkerNameMatchNew{
    position: sticky;
    left: 195px;
}
.stickWorkerLedger{
    position: sticky;
    left: 74px;
    border-right: 1px solid #1c75bc;
}
.stickWorkerLedgerCheckBox{
    position: sticky;
    left: 0px;
    border-right: 1px solid #1c75bc;
}
.stickEmployerLedger{
    position: sticky;
    left: 0px;
}
.width200px{
    width: 200px;
}
.displayFlex{
    display: flex !important;
    top: 0;
    position: sticky;
    z-index: 540;
    padding-bottom: 37px !important;
}
.stickApply{
    position: sticky;
    right: 0;
    border-left: 1px solid #cfcfcf;
    border-right: 1px solid #1c75bc;
}
.header{
    top: 0;
    position: sticky;
    z-index: 540;
}
.zIndex1080{
    z-index: 1080 !important;
}
.zIndex540{
    z-index: 540;
}
.zIndex-10{
    z-index: -10;
}
.selectFields{

}
.selectIcon{
    /* display: none !important; */
}
.linkButton{
    color: #1c75bc !important;
    cursor: pointer !important;
    border:none !important ;
    background-color: unset  !important;
    font-size: 14px !important;
    text-transform:uppercase ;
}
.linkButton:hover{
    text-decoration: underline !important;
}
.linkButton:focus{
    outline: none !important;
}
.linkButton:active{
    margin-top:5px !important;
}
.linkButton:disabled{
    color: #cccccc !important;
}
.fromDate{
    margin: 10px 0 !important;
}
.toDate{
    margin:0 0 10px 0 !important;
}
.borderStd{
    border: 1px solid #cccccc !important;
}
.margin0{
    margin: 0 !important;
}