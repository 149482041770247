
.dashboard-body .top-header{
  margin: 0;
}
.bgcolorf6f6ff{
  background-color: #f6f6ff;
}
.dashboard-welcome-user{
  font-family: Open Sans !important;
  font-size: 25px !important;
  font-weight: 600 !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.16 !important;
  letter-spacing: normal !important;
  color: #333333 !important;
}
.Recruiter_create-account {
    padding: 0 0 85px;
    background-color: #ffffff;
    margin-top: 50px;
  }
  .Rectangle-989.MuiGrid-container {
    width: 90%;
    left: 17.2656%;
    top: 4.58379%;
    margin: 42px auto 0;
    padding: 55px 43px 50px 44px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
  }
.Registration-with-OkayGo {
  /* flex-basis: auto !important; */
    min-height: 120px;
    margin: 0 auto !important;
    width: 420px !important;
    font-family: Open Sans !important;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.82;
    letter-spacing: normal;
    text-align: center;
    color: #455a64;
  }

  .form-label {
    width: 65px;
    height: 22px;
    font-family: Open Sans !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #455a64;
  }

  .Rectangle-995 {
    width: 337px;
  height: 47px;
  margin: 18px 2px 35px 27px;
  padding: 14px 270px 13px 35px;
  border-radius: 9px;
  border: solid 1px #cccccc;
  background-color: #ffffff;
  }

  .Help-us-get-to-know-you-better-with-just-a-few-simple-questions {
    height: 24px;
    margin: 14px 0;
    font-family: Open Sans !important;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #1c75bc;
  }

.field-content
  {
    padding: 0 10px;
    margin-bottom: 20px !important;
  }

  .MuiInputBase-input {
    padding: 10px !important;
  }

  

.MuiButton-root {
    margin: 8px !important;
}

.devider {
  width: 100%;
  height: 0;
  margin: 46px 0;
  border: solid 1px #cccccc;
}

h6.MuiTypography-root.MuiTypography-subtitle1, .cardBottom span {
  font-size: 13px;
  color: #a7a7a7;
  font-family: 'Open Sans';
}

.cardBottom{
  display: inline-block;
  padding: 0 16px !important;
}
.cardBottom:last-child{
  float: right;
}

.dashboardIcon{
  padding: 5px;
  width: 22px;
  vertical-align: top;
  padding-right: 10px;
}

.dashboard-profileImage.MuiCardMedia-media.MuiCardMedia-img {
  width: auto !important;
  margin: auto;
}
