
.rdt_Table{
    width: max-content;
}
.rdt_TableHeadRow{
    background-color: #1c75bc !important;
}
/* .rdt_TableCol{
    color:white !important;
} */

.rdt_TableCol_Sortable{
    color:white !important;
    min-width: 150px;
}
.rdt_TableCell{
    border: 1px solid #dddddd;
}
.tabContainer{
    border: 1px solid #cfcfcf;
    border-radius: 8px;
    border-top-left-radius: unset;
    padding: 15px;
}
.ReactTable .rt-thead.-header{
    background-color: #1c75bc !important;
    color:white !important;
    font-weight: bold;
}
.ReactTable .rt-thead .rt-tr{
    text-align: left !important;
}

.ReactTable .rt-tbody .rt-tr-group{
    border-bottom: solid 1px #c3c3c3 !important;
}
.ReactTable .rt-tbody .rt-td{
    border-right: 1px solid #c3c3c3 !important;
}
.stickCheckbox{
    position: sticky;
    left: 0;
}
.stickWorkerId{
    position: sticky;
    left: 0;
    border-right: 1px solid #1c75bc;
}
.stickWorkerIdnewMatch{
    position: sticky;
    left: 72px;
    border-right: 1px solid #1c75bc;
}
.stickWorkerName{
    position: sticky;
    left: 123px;
}
.stickWorkerNameMatchNew{
    position: sticky;
    left: 195px;
}
.stickApply{
    position: sticky;
    right: 0;
    border-left: 1px solid #cfcfcf;
    border-right: 1px solid #1c75bc;
}
.header{
    top: 0;
    position: sticky;
    z-index: 540;
}
.zIndex1080{
    z-index: 1080 !important;
}
.zIndex540{
    z-index: 540;
}
.zIndex-10{
    z-index: -10;
}
.selectFields{

}
.selectIcon{
    /* display: none !important; */
}

.linkButton{
    color: #1c75bc ;
    cursor: pointer ;
    border:none;
    background-color: unset ;
    font-size: 14px;
    text-transform:uppercase ;
}
.linkButton:hover{
    text-decoration: underline !important;
}
.linkButton:focus{
    outline: none;
}
.linkButton:active{
    margin-top:5px;
}
.fromDate{
    margin: 10px 0 !important;
}
.toDate{
    margin:0 0 10px 0 !important;
}
.columnList{
    overflow: inherit;
    display: flex;
    flex-flow: column wrap;
    align-content: flex-start;
    max-height: 100%;
    max-width: 100%;
    font-size: x-large;
}
