.Recruiter_create-account {
    padding: 0 0 85px;
    background-color: #ffffff;
    margin-top: 50px;
}

.Rectangle-989.MuiGrid-container {
    width: 66.4062%;
    left: 17.2656%;
    top: 4.58379%;
    margin: 42px auto;
    padding: 55px 43px 50px 44px;
    border-radius: 29px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18);
    background-color: #ffffff;
}

.Rectangle-989.profile-accordian {
    width: 66.4062%;
    left: 17.2656%;
    top: 4.58379%;
    margin: 42px auto;
    /* padding: 55px 43px 50px 44px;
    border-radius: 29px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.18); */
    background-color: #ffffff;
    padding: 0;
    box-shadow: none;
}

.form-label {
    width: 65px;
    font-family: Open Sans !important;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    text-align: left;
    color: #455a64;
}

.Rectangle-995 {
    width: 337px;
    height: 47px;
    margin: 18px 2px 35px 27px;
    padding: 14px 270px 13px 35px;
    border-radius: 9px;
    border: solid 1px #cccccc;
    background-color: #ffffff;
}

.Help-us-get-to-know-you {
    height: 24px;
    margin: 14px auto !important;
    font-family: Open Sans !important;
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.28;
    letter-spacing: normal;
    text-align: center;
    color: #1c75bc;
    width: 80%;
}

.field-content {
    padding: 0 10px;
    margin-bottom: 20px !important;
}

.MuiButton-root {
    margin: 8px !important;
}

.devider {
    width: 100%;
    height: 0;
    margin: 46px 0;
    border: solid 1px #cccccc;
}

.field-content .MuiGrid-grid-xs-4 {
    width: 33.333333%;
    display: inline-block;
}

p#simple-modal-description {
    font-size: 16px;
    line-height: 1.63;
    color: #333333;
    padding: 10px;
}

.termsAndCondition p#simple-modal-description {
    height: 400px;
    overflow-y: auto;
}

h2#simple-modal-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 1.15;
    letter-spacing: normal;
    color: #1c75bc;
}

.MuiOutlinedInput-root {
    position: relative;
    border-radius: 9px !important;
}

.MuiFormControl-marginNormal {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    border: 1px solid #cccccc !important;
}

.MuiInput-underline:before {
    border-bottom: none !important;
}

.resume-content {
    display: inline-block;
    vertical-align: top;
    width: 50%;
}

span.inline-text {
    width: 100%;
    display: block;
    padding: 5px;
    line-height: 20px;
}

.year-month-border-right .MuiOutlinedInput-root {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 0px !important;
    border-right: none;
}

.year-month-border-left .MuiOutlinedInput-root {
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 0px !important;
    border-left: none;
}

.optional {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.64;
    color: #707070;
    display: inline-block;
}

.MuiInputLabel-shrink {
    display: none !important;
}

.PrivateNotchedOutline-legendNotched-10,
.PrivateNotchedOutline-legendNotched-14 {
    width: 0px !important;
}

.MuiInputLabel-outlined {
    transform: translate(10px, 16px) scale(1) !important;
    color: #b1adad !important;
}

.has-error .MuiOutlinedInput-notchedOutline,
.has-error .multiple-select-option .css-2b097c-container, .MuiTextField-root.has-error {
    border-color: red !important;
}

.has-error .MuiCheckbox-root, .has-error .MuiSvgIcon-root {
    color: red !important;
}

.multiple-select-option .css-2b097c-container {
    padding: 5px !important;
    border-radius: 9px;
    border: 1px solid #cccccc !important;
}

.css-yk16xz-control {
    border-radius: 9px !important;
    border: 1px solid #cccccc !important;
}

.multiple-select-option .MuiFormLabel-root.MuiInputLabel-root {
    position: absolute !important;
    margin: 0 auto;
    padding-left: 38px;
    color: #cccccc !important;
    top: -15% !important;
}

.css-11hpcgx-control {
    border: none !important;
}

.css-11hpcgx-control:hover,
.css-1hivdsp-control:hover {
    background: none !important;
}

.selected-toggle {
    display: inline;
    margin: 18px 5px;
    padding: 6px 12px;
    border-radius: 17px;
    border: solid 1px #1c75bc;
    background-color: #c8deef;
    color:#1c75bc
}

.all-selected-tab {
    padding: 0;
    margin: 10px 0;
}

.profile-accordian,
.profile-accordian .MuiAccordion-root,
.MuiAccordion-root.Mui-expanded {
    left: 0;
    width: 100%;
    margin: 15px auto !important;
    padding: 10px;
}

.profile-accordian .MuiGrid-item {
    display: flex;
}
.profile-accordian .profile-document .MuiGrid-item {
    display: block;
}
.profile-document span.inline-text{
    width: auto;
    display: inline-block;
}

 .profile-content .Rectangle-989 {
    border-radius: 0;
}

.profile-image {
    width: 111px;
    height: 111px;
    border-radius: 68px;
    margin: 0 auto;
    display: block;
}

.profile-info span {
    vertical-align: top;
    padding: 0 5px;
}

form.reset-password {
    width: 40%;
    margin: 0 auto;
}

.reset-password-title {
    font-family: 'Open Sans';
    font-size: 24px;
    font-weight: bold;
    line-height: 0.96;
    letter-spacing: normal;
    text-align: center;
    color: #1c75bc;
    padding-bottom: 18px;
}

.field-content legend {
    max-width: 0px;
}

.MuiFormHelperText-root {
    line-height: 1 !important;
}

.MuiFormHelperText-contained {
    margin: 6px 0 !important;
}

.resendButton {
    width: auto !important;
}

.form-group label.MuiInputLabel-formControl {
    top: -4px !important;
}

.required:after {
    content: " *";
    color: red;
}

.MuiInput-underline:after {
    border: none !important;
}

.height40 {
    height: 40px !important;
}

a.underline {
    text-decoration: underline;
}

.closeIcon {
    float: right;
    border: 1px solid;
    padding: 4px 9px;
    border-radius: 22px;
    cursor: pointer;
}

.css-1hivdsp-control,
.css-11hpcgx-control {
    border-bottom: none !important;
    min-height: initial !important;
}

.bg-gray input {
    color: gray;
    border: 1px solid #ABADB3;
    padding: 2px 1px;
}
.bg-gray .Mui-focused .MuiOutlinedInput-notchedOutline{
    border-color: rgba(0, 0, 0, 0.23) !important;
    border-width: 1px !important;
}

.css-1okebmr-indicatorSeparator {
    display: none;
}

.css-yk16xz-control,
.css-11hpcgx-control,
.css-1gi1mx2-ValueContainer,
.css-1rhbuit-multiValue,
.css-1wa3eu0-placeholder,
.css-2613qy-menu {
    color: #cccccc !important;
    font-weight: normal !important;
    cursor: text;
    position: relative;
    font-size: 15px;
    box-sizing: border-box;
    align-items: center;
    font-family: Open sans, "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    line-height: 1.1876em;
    letter-spacing: 0.00938em;
}
.MuiDivider-vertical {
    width: 2px !important;
    height: 15px !important;
    display: inline-table;
    background-color: #1c75bc !important;
    vertical-align: middle;
}

.css-1rhbuit-multiValue{
  
    border-radius: 12px !important;
    border: solid 1px #1c75bc !important;
    background-color: #c8deef !important;
    color: #1c75bc !important;
}
.css-12jo7m5{
    color: #1c75bc !important;
}

.Recruiter_create-account .MuiGrid-item {
    padding: 0 10px !important;
}
.verified{
    font-size: 14px !important;
    padding: 1px 7px;
    background-color: rgba(92, 184, 92, 0.19);
    color: #5cb85c !important;
    border-radius: 10px;
    border: 1px solid #5cb85c;
    margin-left: 15px;
}
.login-register .MuiGrid-item{
    padding: 0;
}
.dashboard-container .MuiGrid-item{
    padding-right: 0;
}
.dashboard-container .dashboard-content.MuiGrid-item{
    padding: 3%;
    vertical-align: top;
}

._loading_overlay_content {
    top: 45%;
    left: 45%;
    position: fixed;
  }

svg.MuiSvgIcon-root.helperInfo {
    cursor: pointer;
    vertical-align: middle;
}

.bZGccd {
z-index: 9999 !important;
}