.weekbutton{
    padding: 0 !important;
    margin-right: 5px !important;
    height:40px !important;
    border: 0 !important;
    min-width: 10px !important;
    width: 40px !important;
    border-radius: 50% !important;
}
.weekbutton:hover{
    background-color: unset !important;
}
.weekbutton .MuiToggleButton-label{
    height:18px !important;
    border-radius: 50% !important;
}
.formHeadings{
    /* font-family: OpenSans; */
    font-size: 24px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.88;
    letter-spacing: normal;
    text-align: left;
    text-decoration: underline;
    color: #000000;
}
.smallHeading{
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.31;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: -21px;

}
.ReqExp{
    display: flex;
    flex-direction: column;
}
.form{
    padding: 100px;
}
.makeStyles-formControl-89{
    margin: 0 !important;
}
.rGroup{
    margin-top: 24px;
    display: flex !important;
    flex-direction: row !important;
  }
.MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
    padding: 40px 100px 20px 0;
}
.stepButtons{
    padding: 0 200px 100px 100px;
}
.savenext{
   margin-left: auto;
   width: fit-content;
}
.stepper{
    padding: 100px 200px 0 200px;
}
.TimeField{
    width: 200px;
}
.editor .md-RichEditor-root{
    border: 1px solid black ;
    border-radius: 5px;
    padding: 0 15px;
    margin-left: 20px;
    display: block;
}
.editor .public-DraftEditor-content .md-block-paragraph{
    margin: 5px
}
.ErrorDiv{
    display: flex;
    flex-direction: column;
    text-align: right;
    width: inherit;
    margin-right: 110px;
    color: red;
}