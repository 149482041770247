/* .carousel-root {
    height: 419px;
    margin: 80px 0 54px;
    background-color: rgba(28, 117, 188, 0.14);
  } */
  .carousel.carousel-slider{
    background-color: rgba(28, 117, 188, 0.14) !important;
  }
  .carousel .slider-wrapper {
    width: 1280px !important;
}
  
  .carousel .slide {
    width: 1280px;
    height: 419px;
    margin: 0 !important;
    padding: 70px 67px 0;
    background: none !important;
  }
  
  .carousel .slide img {
    width: 100px !important;
    border-radius: 5px;
    margin: 5% 0;
  }
  
  .myCarousel .myCarousel-right {
    background: #fafafa;
    width: 562px;
    float: right;
    margin-left: auto;
    margin-right: auto;
    padding-top: 3%;
    padding-bottom: 2%;
    padding-left: 0%;
    padding-right: 0%;
    border: 1px solid #ddd;
    height: 249px;
    
  }
  .myCarousel .myCarousel-left {
    width: 458px;
    margin-left: auto;
    margin-right: auto;
    float: left;
    padding-bottom: 8%;
    height: 286px;
  }
  
  .carousel .control-dots {
    padding-left: 5px !important;
    bottom: 5% !important;
    width: 1280px !important;
    text-align: right !important;
    margin: auto !important;
    padding: 0 67px !important;
  }
  
  .myCarousel h3 {
    width: 458px;
    height: 86px;
    font-family: Open Sans;
    font-size: 32px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.34;
    letter-spacing: normal;
    text-align: left;
    color: #1c75bc;
    margin: 0 !important;

  }
  
  .myCarousel h4 {
    text-transform: uppercase;
    margin-top: 0;
    padding-top: 0;
    font-weight: 500;
    color: #787878;
    font-size: 14px;
  }
  .carousal-content-left{
      width: 25%;
  }
  .carousal-content-right{
    width: 75%;
}
  .myCarousel p {
    width: 329px;
    height: 120px;
    margin: 5% 52px;
    font-family: Open Sans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.57;
    letter-spacing: normal;
    text-align: left;
    color: #333333;
    float: right;
    margin-left: 0;
  }
  
  .myCarousel p:before {
    content: "“";
    color: #aaa;
    font-size: 16px;
    font-family: monospace;
    font-weight: 100;
  }
  
  .myCarousel p:after {
    content: "”";
    color: #aaa;
    font-size: 16px;
    font-family: monospace;
    font-weight: 100;
    line-height: 0;
  }
  
  .carousel .control-dots .dot {
    width: 16px !important;
    height: 16px !important;
    background-color: #1c75bc !important;
  }
  
  .carousel.carousel-slider .control-arrow {
    background: #000 !important;
    height: 50px !important;
    position: absolute;
    top: 35% !important;
    display: none;
  }
  
  .App {
    text-align: center;
  }
  
  @media only screen and (max-width: 934px) {
    .carousel-root {
      outline: 0;
      width: 93% !important;
      margin: auto !important;
    }
  
    .carousel.carousel-slider .control-arrow {
      display: none !important;
    }
    .myCarousel {
      background: #fafafa;
      margin-top: -9%;
      width: 88%;
      margin-left: auto;
      margin-right: auto;
      padding-top: 8%;
      padding-bottom: 12.5%;
      padding-left: 5%;
      padding-right: 5%;
      border: 1px solid #ddd;
      height: 269px;
    }
  
    .carousel .slide img {
      width: 24% !important;
      border-radius: 50%;
    }
  }
  .providedBy{
    width: 286px;
    float: right;
    text-align: right;
    font-family: Open Sans;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    color: #333333;
    margin-top: 20px;
  }
  .carousel-status{
      display: none;
  }

  .leftQuote {
    text-align: right;
}
.carousel .slide img.LeftQuoteImg {
    width: auto !important;
}