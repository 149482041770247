.recruiterDashboard .inputIcons{
    position: relative;
    right: -25px;
    top: 30px;
    height: 20px;
}

.recruiterDashboard .filterCLoseButton {
    height: 16px;
    width: 16px;
    /* margin-top: 27px !important; */
    left: -40px;
}

.recruiterDashboard label + .MuiInput-formControl {
    margin-top: 0;
    border: 1px solid #CCC;
    border-radius: 9px;
}
.recruiterDashboard .MuiInputBase-input {
    padding: 14px !important;
}

.recruiterDashboard [class*="MuiOutlinedInput-input"]{
    border: 0;
}

.recruiterDashboard .MuiSelect-select{
    min-width: 40px;
}

.recruiterDashboard .MuiFormControl-marginNormal{
    border: 0 !important;
}
.recruiterDashboard input#joiningDate, .recruiterDashboard input#selectionDate, .recruiterDashboard input#offeredSalary{
    height: 15px !important;
}
.addNewWorker .stepper{
    width: 100%;
}

.addNewWorker .stepper .MuiStepper-alternativeLabel{
    background-color: #fafafa;
}

.addNewWorker .MuiGrid-item {
        /* max-width: 47%; */
        /* margin: 16px; */
        /* display: inline-block; */
}

/* .addNewWorker .stepButtons {
    padding: 0;
} */

.addNewWorker .MuiOutlinedInput-input {
    padding: 16px 14px !important;
}

.addNewWorker .MuiOutlinedInput-root{
    border-radius: 4px !important;
}

.addNewWorker .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
    padding: 0px;
}

.addNewWorker .language-container {
    display: contents;
}

.experienceContainer {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
}

.experienceContainerlabel {
    font-size: 16px;
    font-weight: 700;
}

.MuiInputBase-formControl legend{
    width: 0 !important;
}
.flexDisplay.recruiterDB-action{
    display: inline-block;
}
.bykFds table td, .bykFds table th{
    border-left: 1px solid #cfcfcf;;
}
.bykFds table tr:last-child td {
    border-bottom: 0;
}

.bykFds table td {
    vertical-align: baseline;
    margin: 0;
    border-bottom: 1px solid #cfcfcf;
    vertical-align: top;
    background-color: white;
}

.datepickerHeight input{
    height: 16px !important;
}


/* Tostify */



.Toastify__toast-container--top-right {
    top: 1em;
    right: 1em;
}

.Toastify__toast-container {
    z-index: 9999;
    -webkit-transform: translateZ(9999px);
    position: fixed;
    padding: 4px;
    width: 320px;
    box-sizing: border-box;
    color: #fff;
}

.Toastify__toast--success {
    background: #07bc0c;
}
.Toastify__toast--error {
    background: #e74c3c;
}

.Toastify__toast {
    position: relative;
    min-height: 64px;
    box-sizing: border-box;
    margin-bottom: 1rem;
    padding: 8px;
    border-radius: 4px;
    box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    max-height: 800px;
    overflow: hidden;
    font-family: sans-serif;
    cursor: pointer;
    direction: ltr;
}

.Toastify__toast-body {
    margin: auto 0;
    padding: 6px;
}

.Toastify__close-button {
    color: #fff;
    background: transparent;
    outline: none;
    border: none;
    padding: 0;
    cursor: pointer;
    opacity: .7;
    transition: .3s ease;
    -ms-flex-item-align: start;
    align-self: flex-start;
}

.Toastify__close-button>svg {
    fill: currentColor;
    height: 16px;
    width: 14px;
}
.recruiter-dahboard-action .recruiterDB-action{
    text-align: left;
}

.recruiter-dahboard-action .recruiterDB-action .margin0{
    margin: 0 !important;
}

.recuiter-dashboard-datepicker .MuiInputLabel-formControl{
top: -10px;
    padding-left: 12px;}

    .offeredSalaryDropdown .salaryType{
        right: -8px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
    }
    .salaryType .MuiSelect-iconFilled {
        right: 0px;
    }

     .jobPopupInput {
        margin: 20px 0 20px 20px !important
    }

   /* .recruiterDashboard .MuiInputBase-formControl legend {
        width: auto !important;
    } */

    .jobPopupInput .MuiInputBase-input, #last_salary {
        padding: 15px !important;
    }

    .jobPopupInput .MuiAutocomplete-inputRoot[class*="MuiOutlinedInput-root"] {
        padding: 0 !important;
    }
    .jss53:focus, .jss56:focus {
        outline: none i !important;
    }

    .postJob {
        margin: 100px auto;
        text-align: center;
    }

    .workerList .MuiTablePagination-root:last-child {
        padding: 0;
        width: 80%;
        display: inline-block;
    }

    .workerList .MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar {
        display: flex;
    }

    .firstpageDatePicker .MuiInput-underline {
        border: 1px solid #b1adad !important;
        border-radius: 5px;
        padding: 5px;
    }

    .firstpageDatePicker label{    
        padding: 10px;
    }
    .sidebarFilter .MuiInput-underline:before{
        border-bottom: 1px solid rgba(0, 0, 0, 0.42) !important;
    }

    .profile-content.recruiterDashboard .candidate-name{
        margin-left: 0 !important;
    }
    .profile-content.recruiterDashboard .jobTitle{
        margin-left: 0 !important;
        font-size: 18px !important;
    }



    .profile-content.recruiterDashboard .MuiInputLabel-outlined.MuiInputLabel-shrink, .MuiDrawer-paperAnchorRight .MuiInputLabel-outlined.MuiInputLabel-shrink{
        transform: translate(14px, -6px) scale(0.75) !important;
        display: block !important;
    }
    .profile-content.recruiterDashboard .MuiInputBase-formControl legend, .MuiDrawer-paperAnchorRight .MuiInputBase-formControl legend {
        width: auto !important;
    }

    .candidate-email210 div div{
        word-wrap: break-word;
        hyphens: auto;
        max-width: 210px;
    }