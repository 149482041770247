.weekbutton{
    padding: 0 !important;
    margin-right: 5px !important;
    height:20px !important;
    border: 0 !important;
    min-width: 10px !important;
    width: 20px !important;
    border-radius: 50% !important;
}
.weekbutton:hover{
    background-color: unset !important;
}
.weekbutton .MuiToggleButton-label{
    height:18px !important;
    border-radius: 50% !important;
}
.MainHeading{
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.35;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 20px !important;
}
.subHeading{
    font-size: 18px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    margin-bottom: 24px !important;
}
.key{
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    font-weight: 500;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}
.value{
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
}
.jobDetail .MuiGrid-item {
    margin: 0;
    box-sizing: border-box;
    padding: 0 ;
}
.fieldRow{
    margin-bottom: 16px !important;
}
.semiSubHeading{
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: normal;
    text-align: left;
    color: #000000;
    text-decoration: underline;
    margin-bottom: 16px !important;
}
